/**
 * Will return Vimeo iframe (based on vimeoID) o YouTube frame if present.
 */
const getVideoFrame = ({youtubeUrl, vimeoId}) => {
    if (youtubeUrl) {
        return `<iframe width="320" height="180" src="${youtubeUrl}?controls=0&autoplay=1&mute=1&loop=1&modestbranding=1&autohide=1&showinfo=0&controls=0" title="En directo" frameborder="0" allow="autoplay;encrypted-media"></iframe>`
    } else {
        return `<iframe data-a="a" src="https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&autopause=0&background=1&muted=1" width="320" height="180" frameborder="0" allow="autoplay"></iframe>`
    }
}

export const template = ({url, vimeoId, youtubeUrl}) => `<div class="bottomLive">
            <div class="bottomLive__container">
                <div class="bottomLive__control">
                    <div class='bottomLive__badge'>
                        <a href="${url}" class="headerLive__link">
                            <span class="fa-stack">
                          <i class="fa fa-circle-thin fa-stack-2x"></i>
                          <i class="fa fa-video-camera fa-stack-1x"></i>
                        </span>
                            Emitiendo en directo
                        </a>
                    </div>

                    <div class="bottomLive__close">
                        <a href="#" class="bottomLive__closeLink">
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>

                <a href="${url}" class="headerLive__link">
                <div class="bottomLive_frame">
                        ${getVideoFrame({youtubeUrl, vimeoId})}
                </div>
                </a>
            </div>
        </div>`;

export const smallPreviewTemplate = (url) => `<a href="${url}" class="headerLive__link headerLive__link--visible">
<span class="fa-stack">
                  <i class="fa fa-circle-thin fa-stack-2x"></i>
                  <i class="fa fa-video-camera fa-stack-1x"></i>
</span>
</a>`;
