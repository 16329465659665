/**
 * Acción por defecto realizada por IntlTelInput cuando no se ha proporcionado país
 * @param {string} defaultCountry Si no se puede indicar país, establecer este iso2 (es)
 * @param {function} callback Función de retorno proporcionada por IntlTelInput
 */
const intlDefaultGeoIpLookUp = (defaultCountry, callback) => {
    const country = sessionStorage.getItem('ei-country');
    if (country) {
        console.log('Country fetched from LS');
        return callback(country);
    }

    $.ajax({
        url: '/g-u-gi',
        type: 'GET',
        success: function(data){
            if (data && data.hasOwnProperty('country_code')
                && data['country_code'] !== null) {
                sessionStorage.setItem('ei-country', data['country_code']);
                return callback(data['country_code']);
            } else {
                console.error('Server cannot define visitor country. Switching back to ' + defaultCountry);
                return callback(defaultCountry);
            }
        },
        error: function(xhr, err){
            console.error('Imposible to define visitor country. Switching back to ' + defaultCountry);
            return callback(defaultCountry);
        }
    });
}

/**
 * Cuando se está construyendo un IntlTelInput, revisar si tiene un campo de prefijo asociado,
 * de forma que cuando se cambie un parámetro de IntlTelInput, se cambie también el prefijo asociado.
 * @param {jQuery} $element Selector JQuery donde se está construyendo IntlTelInput
 * @return {null|jQuery} null si no hay un selector de provincia o selector JQuery con el campo prefijo
 */
const getPrefixField = ($element) => {
    const prefixData = $element.data('tel-prefix-field-id');
    if (!prefixData) {
        return null;
    }

    const prefixSelector = $('#' + prefixData);
    if (!prefixSelector.length) {
        return null;
    }

    return prefixSelector;
}

/**
 *
 * @param {object} widget
 */
const intlTelInputError = (widget) => {
    const element = widget.telInput;
    const $element = $(element);
    const errorTxt = 'Debe introducir un número de teléfono válido';


    $element.on("blur", function() {
        if ($element.val().trim() !== '' ) {
            const container = $element.closest('.intl-tel-input');
            const parent = container.parent('.form-tel');
            if (widget.isValidNumber()) {
                parent.removeClass('error');
                if (container.prev().length > 0 && container.prev().hasClass('errors')) {
                    container.prev().hide();
                }
            } else {
                parent.addClass('error');
                if (container.prev().length > 0 && container.prev().hasClass('errors')) {
                    container.prev().html('<p>' + errorTxt + '</p>');
                    container.prev().show();
                } else {
                    container.before('<div class="errors"><p>' + errorTxt + '</p></div>');
                }
            }
        }
    });

    // Reset error
    $element.on("keyup change", function(){
        const container = $element.closest('.intl-tel-input');
        const parent = container.parent('.form-tel');
        parent.removeClass('error');
        if (container.prev().length > 0 && container.prev().hasClass('errors')) {
            container.prev().hide();
        }
    });
}
/**
 * Construye el plugin IntlTelInput sobre un selector de JQuery usando la librería nativa.
 * Usa async para poder hacer un await mientras se carga la librería IntlTelInput.
 * @param {jQuery} $element Selector JQuery sobre el que construir el plugin
 * @return Promise<object>
 */
export const intlTelInputBuilder = async ($element) => {
    const intlTelInput = await import('intl-tel-input');
    await import('intl-tel-input/build/css/intlTelInput.css');
    const utils = await import('intl-tel-input/build/js/utils');

    const customContainer = $element.data('tel-custom-container');
    const separateDialCode = $element.data('tel-separate-dial-code');
    const initialCountry = $element.data('tel-initial-country');
    const onlyCountries = $element.data('tel-only-countries');
    const preferredCountries = $element.data('tel-preferred-countries');
    const defaultCountry = $element.data('tel-default-country');


    const widget = intlTelInput.default($element[0], {
        customContainer: customContainer,
        separateDialCode: separateDialCode,
        initialCountry: initialCountry,
        onlyCountries: onlyCountries,
        preferredCountries: preferredCountries,
        utils: utils,
        geoIpLookup: (callback) => {
            intlDefaultGeoIpLookUp(defaultCountry, callback);
        },
    })

    intlTelInputError(widget);
    widget.setNumber($element.val())

    //Obtener el selector que se enviará por el formulario (IntlTel es una capa adicional)
    // Obtenemos también el campo prefijo asociado al número de teléfono (si se ha dado)
    const textSelector = $('#' + $element.attr('id').replace('_tel', '') );
    const prefixSelector = getPrefixField($element);

    // $element.closest('form').on('submit', function(e){
    $element.on('change', function(e){
        if (widget.isValidNumber()) {
            textSelector.val(widget.getNumber());

            if(prefixSelector) {
                const countryData = widget.getSelectedCountryData();
                prefixSelector.val( (countryData && countryData.iso2 ? countryData.iso2 : null) );
            }

        } else {

            textSelector.val(null);
            if (prefixSelector) {
                prefixSelector.val(null);
            }
        }
    });

    return widget;
}
