import {getUserData} from '../modulos/modulesUtils'

/**
 * Carga la caja de login del menú superior
 */
const cajaLogin = () => {
    fetch(unmaskpath(JSVAR.path_cl), {
        method: 'POST',
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('User menu request has returned non 200 code');
            } else {
                return response.text();
            }
        })
        .then(response => {
            const cajaHandler = document.querySelector('#box-login');
            cajaHandler.innerHTML = response;

            //Ejecutar el código script enviado
            const scripts = cajaHandler.getElementsByTagName('script');
            for (let script of scripts) {
                eval(script.innerHTML);
            }

        })
        .catch(err => {
            console.error('Cannot load User-Menu cause ', err);
        });

}

/**
 * Realiza una llamada al servidor cada X segundos, evitando que se cierre la sesión
 */
const keepSession = () => {
    const callServer = () =>
        fetch(unmaskpath(JSVAR.path_ka), {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

    //Call every 15 minutes.
    setInterval(callServer, 15 * 60 * 1000);
}

/**
 * Cargamos el contenido de Última hora vía ajax
 */
const breakingNews = () => {
    fetch(unmaskpath(JSVAR.path_uh))
        .then(response => {
            if (!response.ok) {
                throw new Error('User menu request has returned non 200 code');
            } else {
                return response.text();
            }
        })
        .then(html => {
            const alertHolder = document.querySelector('#alerts-block');
            if (alertHolder) {
                alertHolder.innerHTML = html;
            }
        })
        .catch(err => {
            console.error('Cannot load breaking news cause ', err);
        });
}

/**
 * Cambiamos todos los enlaces a dominios externos como nueva pestaña
 */
const externalLinks = () => {
    document.querySelectorAll('.article-body a').forEach(link => {
        const attrVal = (link.href.indexOf('estrategiasdeinversion.com') !== -1) ? '_self' : '_blank';
        link.setAttribute('target', attrVal);
    });
}

/**
 * Contiene el funcionamiento de los botones de una página Landing (noticia, sección o contenido) que se
 * muestra cuando el usuario no tiene permisos e invita a suscribirse o loguearse (si no está ya logueado).
 */
const landingPageOptions = () => {
    const targetAnchor = document.querySelector('.md-info-premium-3');
    if (targetAnchor) {
        document.querySelectorAll('.goToSuscription').forEach(el => {
            el.addEventListener('click', function (e) {
                e.preventDefault();
                return $(function () {
                    $("html, body").animate({
                        scrollTop: $('.md-info-premium-3').offset().top - 70
                    }, 2000);
                })
            })
        })
    }

    const loginBtn = document.querySelectorAll('.logginBtn')
    loginBtn.forEach(el => {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            app.$('.user-session-button').trigger('click');
        })
    })

    getUserData()
        .then((data) => {
            //Si hay datos es que por lo menos es registrado. En tal caso, eliminar los botones de login.
            loginBtn.forEach(item => item.remove(item))
        })
        .catch(() => {
            //Mantenemos el catch porque saltará cuando no haya usuario registrado.
        })
}
/**
 * Propaga los UTM que contiene la página actual a todos los enlaces dentro de Estrategias.
 * Si el enlace contiene UTM propios, estos no se modifican.
 */
const addUtmOnClick = () => {

    document.addEventListener('click', function (e) {
        if(!e.target.closest('a')) {
            return;
        }

        let linkUrl;
        try{
            linkUrl = new URL(e.target.closest('a').getAttribute('href'));
        } catch (e) {
            return;
        }

        if (!linkUrl.host.endsWith('estrategiasdeinversion.com')) {
            return;
        }

        e.preventDefault();
        const currentUrl = new URL(window.location.href);
        for(let pair of currentUrl.searchParams.entries()) {
            if (pair[0].substring(0,4) !== 'utm_' && pair[0] !== 'campaign') {
                continue;
            }

            //Capturamos el utm_* de la url actual. Si el link no la lleva, se la añadimos.
            if (!linkUrl.searchParams.has(pair[0])) {
                linkUrl.searchParams.set(pair[0], pair[1]);
            }
        }

        if (e.target.getAttribute('target') === '_blank') {
            window.open(linkUrl.href, '_blank').focus();
        } else {
            window.location.href = linkUrl.href;
        }
    });
}

export default function () {
    cajaLogin();
    keepSession();
    breakingNews();
    externalLinks();
    landingPageOptions();
    addUtmOnClick();
}
