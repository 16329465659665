import {getCookie, setCookie, getUserData} from "../modulesUtils";

/**
 * Script encargado de mostrar un popup al usuario X días antes de que caduque su suscripción premium
 * Aparecerá un modal en la página que le recordará que en X días se quedará sin premium, con un botón
 * que le guía a la página de renovación.
 * El número de días a partir del cuál se comienza a mostrar el popup queda definido en el controlador
 * de la llamada AJAX, que en el momento de redactar este texto son 20 días.
 *
 * Entrados en la cuenta atrás de 20 días y para no saturar al usuario, sólo se mostrará el popup recor-
 * datorio una vez al día (cada 24 horas), aunque este comportamiento también es configurable desde el
 * controlador. Este comportamiento se controla mediante una COOKIE, que de existir, bloqueará la ejecu-
 * ción de Javascript. El servidor indicará en la respuesta cuántos días han de pasar para que se vuelva
 * a mostrar el mensaje
 *
 * Si por el contrario quedan más de X días, y con el fin de ahorrarnos llamadas AJAX, creará la cookie
 * igualmente sin mostrar el mensaje, para que la función comience a ejecutarse cuando la suscripción entre
 * en los últimos X días.
 *
 * Como novedad en esta versión 2, esperaremos al evento creado por cajaLogin que incluye el tipo de usuario
 * actual, lanzándose la función solo cuando es un usuario premium (quedando excluido premium 1 semana, registrado
 * y anónimo; los cuales no tienen caducidad como tal). Adicionalmente, también comprobará si el evento ha sido ya
 * lanzado y leerá los datos necesarios a posteriori.
 *
 */

export default ()=>{
    try{
        initPopUpRenewal();
    }catch(e){
        if(_WPCONST_ENV_ === 'DEVELOPMENT') console.error(e);
        console.error('The module could not be initialized because an error was detected.');
    }
};

const COOKIE_NAME = 'suscpop';

/**
 * Comprueba si la suscripción del usuario está a punto de caducar. En primer lugar, espera el evento lanzado por cajaLogin que contiene
 * si es un usuario premium (el resto de licencias no se tiene en cuenta; registrado, una semana premium o STP). Si es premium, realizamos
 * la llamada AJAX que nos indica qué hacer.
 */
function initPopUpRenewal(){
    //Si ya existe la Cookie, dejamos de ejecutar el método, pues ya ha sido avisado.
    if(getCookie(COOKIE_NAME) !== null) return;

    //Obtenemos los datos del usuario a través de cajaLogin
    getUserData()
        .then(userData => {
            if(userData && userData.hasOwnProperty('premium') && userData.premium === true){
                const prefixUri = (typeof JSVAR !== 'undefined' && JSVAR.hasOwnProperty('base_url')) ? unmaskpath(JSVAR['base_url']) : '';
                fetch(prefixUri + '/substatus')
                    .then(result => result.json())
                    .then(data => {
                        if(data && data.hasOwnProperty('status') && data.status === true)
                            return processResponse(data);
                    })
                    .catch(e => console.error('Cannot connect with subscription service'));
            }
        })
        .catch(e => {}); //<-- el usuario es anónimo
}

/**
 * Espera los resultados del JSON por parte del servidor. Recibe un objeto que es destructurado ES6.
 * @param {boolean} status Si la operación fue correctamente
 * @param {string|null} name Nombre del usuario si está en el rango de mostrar el aviso; null si todavía queda tiempo o ha caducado
 * @param {number|null} remaining Días que le quedan hasta que caduque la suscripción; null si está fuera de ese rango
 * @param {number} next Días que han de pasar hasta que se vuelva a ejecutar este proceso. El número de días hasta que la suscripción entre
 *                        en período de aviso o 1 día si está en el rango.
 */
function processResponse({status, name, remaining, next}){
    //Si se adjunta un nombre y un "remaining", es que está dentro de plazo de caducidad.
    if(typeof name === 'string' && typeof remaining === 'number')
        drawPopUp(name, remaining);

    //Si se adjunta un campo next, indica que hay que establecer la cookie
    if(typeof next !== 'undefined'){
        const value = (typeof remaining === 'number') ? remaining : 1;
        setCookie(COOKIE_NAME, value.toString(), next);
    }
}

/**
 * Muestra el modal al usuario informándole que está en el plazo de precaducidad. Envolvemos la función en
 * un wrapper JQuery para asegurarnos de que está cargado.
 * @param {string} name Nombre del usuario
 * @param {number} days Número de días hasta caducidad
 */
function drawPopUp(name, days)
{
    let left;
    if(days === 1) left = 'mañana';
    else if(days === 0) left = 'hoy';
    else left = 'en ' + days + ' días';

    if(name.trim() === '') name = 'usuario';

    $(function(){
        if (typeof lity === 'function'){
            const html = `<div id="subscriptionEnding" class="lity-hide modal-login-boletin modal-advise">
        <div class="content-modal modal-800-200">
            <button class="lightbox-close" data-lity-close="">Cerrar</button>
            <div class="row title">
                Aviso de caducidad de su suscripción
            </div>
            <span class="rowseparator"></span>
            <div class="row body">
                Hola ${name},<br>
                <p>Tu suscripción premium caducará ${left}.</p>
                <a href="https://www.estrategiasdeinversion.com/mi-suscripcion" class="form-button btn">Renovar ahora</a>
            </div>
        </div>
    </div>`;
            const $div = $(html);
            $('body').append($div);
            const lightbox = lity();
            lightbox('#subscriptionEnding');
        }
    });
}