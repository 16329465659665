import {customCotFormat, getColorFormat} from "../streaming/utils";

/**
 * Obtiene un array de UTMs y crea el query string correspondiente
 * @param utms
 * @returns {string}
 */
export const utmsToQueryString = (utms) =>
{
    const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    let queryString = '';

    if (utms) {
        const keys = Object.keys(utms);

        if (keys.length > 0) {
            queryString += '?';
            keys.forEach(function(key) {
                queryString += camelToSnakeCase(key) + '=' + utms[key] + '&';
            });
            queryString = queryString.slice(0, -1);
        }
    }

    return queryString;
}

/**
 * Extrae de forma controlada las propiedades de un ticker en formato JSON
 * @param {Object} ticker Información de un ticker en formato JSON
 * @return {{vol: (number|Object.vol|metainfo.defaults.styles.vol|{trackPrice, linestyle, visible, color, transparency, plottype, linewidth}|metainfo.styles.vol|{histogramBase, title}), varpercStr: *, last: *, volStr: *, name: *, link: string, lastStr: string, tickerId: Object.tickerId, varperc: Object.varperc, uri: string}}
 */
export const getTickerInfo = (ticker) =>
{
    const dataType = (ticker.hasOwnProperty('dataType')) ? ticker.dataType : ticker.type;
    const marketId = (ticker.hasOwnProperty('marketId')) ? ticker.marketId : ticker['mar'];
    let info = {
        'tickerId' : ticker.tickerId,
        'name': (ticker.hasOwnProperty('niceName') && ticker.niceName.trim() !== '') ? ticker.niceName : ticker.name,
        'last': ticker.last,
        'varperc': ticker.varperc,
        'vol': ticker.vol,

        'lastStr': customCotFormat('last', ticker.last),
        'varpercStr': customCotFormat('varPerc', ticker.varperc),
        'volStr': customCotFormat('vol', ticker.vol),

        'lastClass': getColorFormat(ticker.last),
        'varperClass' : getColorFormat(ticker.varperc),

        'market': parseInt(marketId),

        'uri': getBaseQuoteSlug("/cotizaciones", dataType, marketId, ticker.status),
        'link': (ticker.hasOwnProperty('link')) ? ticker.link : (ticker.hasOwnProperty('slug') ? ticker.slug : '')
    };
    info['uriLink'] = info.uri + '' + info.link;
    return info;
};

/**
 * @deprecated JQUERY !!!
 * Realiza una consulta GET al servidor y devuelve el resultado en formato promesa
 * @param {string} url Dirección URL a la que efectuar la consulta
 * @param {string} type Determina el formato que se espera. Por defecto, JSON.
 * @return {Promise<any>}
 */
export const fetchData = (url, type = 'json') => {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: unmaskpath(url),
            dataType: type,
            type: 'get',
            async: true,
            error: function(xhr, ajaxOptions, thrownError){
                return reject(xhr, ajaxOptions, thrownError);
            },
            success: function(response){
                if(!response) return reject(null);
                if(response.hasOwnProperty('error') && response.error !== '') return reject(response.error);
                else return resolve(response);
            }
        })
    });
};

/**
 * @deprecated use Fetch
 * Encargado de enviar datos al servidor
 * @param {String} url Dirección url a la que enviar la petición
 * @param {Object} data Datos a enviar al server
 * @return {Promise<any>}
 */
export const postData = (url, data)=>{
    console.warn('Try to avoid postData function');

    return new Promise((resolve, reject) => {
        $.ajax({
            url: unmaskpath(url),
            data: data,
            type: 'POST',
            error: (xhr, ajaxOptions, thrownError)=>reject(xhr, ajaxOptions, thrownError),
            success: (response)=> (response) ? resolve(response) : reject(null)
        });
    });
};

/**
 * Función auxiliar para obtener el valor de una cookie por su clave si existiese
 * @param {string} name Nombre de la cookie a buscar
 * @returns {*}
 */
export const getCookie = (name) => {
    const dc = document.cookie;
    const prefix = name + "=";
    let end = null;
    let begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
    }
    else
    {
        begin += 2;
        let end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
};

/**
 * Función auxiliar para establece una cookie
 * @param {string} cname Nombre de la cookie
 * @param {string} cvalue Valor de la cookie
 * @param {number} exdays Número de días en la que caduca la cookie
 */
export const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

/**
 * Función diseñada para obtener los datos del usuario a través del evento lanzado por cajaLogin. Espera a que el
 * evento sea lanzado o bien obtiene, si ha sido lanzado previamente, los datos del usuario a través de la propiedad
 * app.userData. Debe usarse como promesa.
 * @return Promise Con los datos del usuario o devolviendo un fallo si el usuario es anónimo y no está registrado
 */
export const getUserData = () => {
    return new Promise((resolve, reject) => {
        //Si el evento ya ha sido lanzado, cajaLogin habrá dejado las variables de usuario en app.userData
        if(app && app.hasOwnProperty('userData')){
            return (app.userData.hasOwnProperty('login') && app.userData.login === true) ? resolve(app.userData) : reject(null);
        }else{
            //Si no, esperamos a que cargue caja-login, para ver si está logado.
            //Si no lo está, no tiene sentido ver cuando caduca su suscripción
            document.addEventListener('LoginLoaded', (event) => {
                return (event.detail && event.detail) ? resolve(event.detail) : reject(null);
            });
        }
    });
};

/**
 * A partir de un timestamp en milisegundos, devuelve la hora de dicho timestamp si la fecha es de hoy
 * o devuelve la fecha en caso contrario.
 */
export const quotesTimeDate = (timestamp) => {
    var date = new Date(0);
    date.setUTCMilliseconds(timestamp);

    var nowDate = new Date();
    if (date.toDateString() === nowDate.toDateString()) {
        const hour = ('0' + date.getHours()).slice(-2),
            minute = ('0' + date.getMinutes()).slice(-2),
            seconds = ('0' + date.getSeconds()).slice(-2);
        return `${hour}:${minute}:${seconds}`;
    } else {
        const day = ('0' + date.getDate()).slice(-2),
            month = ('0' + (date.getMonth() + 1)).slice(-2);
        return `${day}/${month}/${date.getFullYear()}`;
    }
}

export const unmaskpath = (string) => string ? string.replace(/##/g,'/') : string


export const getBaseQuoteSlug = (baseUri, dataType, marketId, status) => {

    var stock = (dataType == 0 || dataType == 13 || dataType == 6) ? true : false;
    if (dataType == 100 || dataType == 4 || dataType == 0 || dataType == 13 || dataType == 6) baseUri = baseUri+'/indices/';
    else if (dataType == 2) baseUri = baseUri+'/divisas/';
    else if (dataType == 12) baseUri = baseUri+'/materias-primas/';
    else if (dataType == 14) baseUri = baseUri+'/criptomonedas/';

    let slug = '';

    if (status && status == 3)
        slug = 'otros-mercados/';
    else {
        switch (parseInt(marketId)) {
            case 1058: // mercado-continuo
                slug = (stock) ? 'mercado-continuo/' : '';
                break;
            case 7024: // MAB
                //slug = (stock) ? 'mab/' : '';
                slug = (dataType == 13) ? 'mab-sicav/' : ''; //slug;
                break;
            case 1138: // latibex
                slug = (stock) ? 'latibex/' : '';
                break;
            case 5386: // BME GROWTH
                slug = (stock) ? 'bme-growth/' : '';
                break;
            case 65: // nyse
                slug = (stock) ? 'nyse/' : '';
                break;
            case 51: // euronext lisboa
                slug = (stock) ? 'euronext-lisboa/' : '';
                break;
            case 67: // nasdaq omx
                slug = (stock) ? 'nasdaq-omx/' : '';
                break;
            case 6: // euronext bruselas
                slug = (stock) ? 'euronext-bruselas/' : '';
                break;
            case 25: // euronext paris
                slug = (stock) ? 'euronext-paris/' : '';
                break;
            case 38: // euronext amsterdam
                slug = (stock) ? 'euronext-amsterdam/' : '';
                break;
            case 10000: // otros-mercados
                slug = (stock) ? 'otros-mercados/' : '';
                break;
            default:
                slug = '';
                break;
        }
    }

    var uri = baseUri + slug;

    return uri;
}

export const sendBeacon = (route, data) => {
    if ('sendBeacon' in navigator) {
        navigator.sendBeacon(route, data);
    } else {
        fetch(route, {
            method: 'POST',
            body: data ? JSON.stringify(data) : ''
        });
    }
}