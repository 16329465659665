const defaultOptions = {
    wrapperClass:               "scrollwrap",
    slideClass:                 "slide",
    navigation: {
        nextEl:            '.slide-next',
        prevEl:            '.slide-prev',
        disabledClass:     "disabled",
    },
    setWrapperSize:             true,
    autoHeight:                 true,
    preventClicks:              false,
    preventClicksPropagation:   false,
    on: {
        init: function(swiper){
            swiper.el.classList.add('initialized');
            swiper.el.parentNode.classList.add('initialized');

            // Incluimos el if porque detectamos problemas en el navegador si no existe el wrapper ni la function css
            if (this.wrapper && typeof this.wrapper.css === 'function') {
                this.wrapper.css("height", "");
                this.update();
            }

            this.update();
        },
        slideChange: (a) => {
            console.log(a);
        }
    },
};

const tableOptions = {
    slidesPerView: 1, // or auto
    breakpoints: {
        1004: {
            slidesPerView: 'auto',
            freeMode: true
        },
        740: {
            slidesPerView: 1,
            freeMode: false
        },
        615: {
            slidesPerView: 'auto',
            freeMode: true
        }
    }
};

/**
 * Envuelve el originalContent con un div de la clase className
 * @param {HTMLElement} originalContent Elemento original
 * @param {string} className Nombre de la clase
 * @return {HTMLElement} Elemento div.className cuyo hijo es originalContent
 */
const wrap = (originalContent, className) => {
    const wrapper = document.createElement('div');
    wrapper.classList.add(className);
    wrapper.appendChild(originalContent.cloneNode(true));
    return wrapper;
}

/**
 * Elimina todos los hijos del element y establece newChild como único hijo
 * @param {HTMLElement} element Elemento que será afectado
 * @param {HTMLElement} newElementChild Elemento que será único hijo de element
 */
const setOnlyChild = (element, newElementChild) => {
    while(element.firstChild) {
        element.removeChild(element.firstChild);
    }
    element.appendChild(newElementChild);

}
/**
 * Crea el contenedor Swiper para el breadcrumb
 * @param {HTMLElement} originalContainer
 * @return {HTMLElement}
 */
const createSwiperContainer = (originalContainer) => {
    const scrollable = document.createElement('div');
    scrollable.classList.add('scrollable');

    const scrollwrap = document.createElement('div');
    scrollwrap.classList.add('scrollwrap');

    const children = originalContainer.children;
    for(let i = 0; i < children.length; i++) {
        scrollwrap.appendChild(wrap(children[i], 'slide'));
    }

    const prevButton = document.createElement('a');
    prevButton.classList.add('slide-prev');

    const nextButton = document.createElement('a');
    nextButton.classList.add('slide-next');

    scrollable.appendChild(scrollwrap);
    scrollable.appendChild(prevButton);
    scrollable.appendChild(nextButton);

    return scrollable;
}

/**
 * Convierte el breadcrumb al formato esperado por Swiper y carga este último
 * @return {Promise<null|*>}
 */
export const breadCrumbSwiper = async () => {
    const breadContainer = document.querySelector('.md-slider-breadcrumbs');
    if (!breadContainer) {
        return null;
    }

    if (window.innerWidth < 615) {
        //Si es un móvil, no cargar Swiper.
        breadContainer.classList.add('loaded');
        return null;
    }

    const breadCrumbContent = createSwiperContainer(breadContainer);
    setOnlyChild(breadContainer, breadCrumbContent);


    const breadCrumbOptions = {...defaultOptions, slidesPerView:"auto"}
    const {Swiper, Navigation} = await import('swiper');
    Swiper.use([Navigation]);
    return new Swiper(breadCrumbContent,breadCrumbOptions);
}

/**
 * Detecta cuando hay una tabla swippable, la convierte al formato esperado y carga Swiper
 * @return {Promise<null>}
 */
export const tableSwiper = async () => {
    if (window.innerWidth > 1004) {
        document.querySelectorAll('.md-slider-table').forEach(table => {
            table.classList.add('initialized');
        });
        return null;
    }

    document.querySelectorAll('.md-slider-table').forEach(table => {
        const tableContent = createSwiperContainer(table);
        setOnlyChild(table, tableContent);

        const breadCrumbOptions = {...defaultOptions, slidesPerView:"auto"}
        import('swiper').then(({Swiper, Navigation}) => {
            Swiper.use([Navigation]);
            return new Swiper(tableContent,breadCrumbOptions);
        })
    });
}
