/**
 * Lanza un evento sobre document (con polyfill para IE 11)
 * @param {string} eventName Nombre del evento
 * @param {string|Object} eventValue Contenido del evento
 * @param {Object} params Parámetros del evento
 */
export const dispatchEvent = (eventName, eventValue = null, params) => {
    if (!!eventValue) {
        return dispatchCustomEvent(eventName, eventValue, params)
    }
    let event;

    if(typeof Event === "function") {
        event = new Event(eventName);
    } else {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }

    document.dispatchEvent(event);
}

/**
 * Lanza un evento con información adicional sobre document (con polyfill para IE 11)
 * @param {string} eventName Nombre del evento
 * @param {string|Object} eventValue Contenido del evento
 * @param {Object} params Parámetros del evento
 */
const dispatchCustomEvent = (eventName, eventValue, params) => {
    let event;

    if (typeof CustomEvent === 'function') {
        event = new CustomEvent(eventName, {'detail': eventValue});
    } else {
        const params = params || { bubbles: false, cancelable: false, detail: eventValue };
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, params.bubbles, params.cancelable, params.detail);
    }

    document.dispatchEvent(event);
}
