import {checkUserConsentForPropose} from "./consent";

const GOOGLE_ANALYTICS_VENDOR_CODE = 'c:googleana-4TXnJigR';

/**
 * @param {number} pages_viewed
 */
export const sendPushInfiniteScroll = (pages_viewed) => {
    sendPush({
        'event': 'web-vitals',
        'event_category': 'infinite-scroll',
        'event_action': `page-${pages_viewed}`,
        'event_label': '(not set)',
        'event_value': '(not set)'
    });
}

export const sendContentGroup = (contentType, idContent) => sendPush({
    'event': 'select_content',
    'content_type': contentType,
    'item_id': idContent
});

export const sendPush = (info, vendor = null)=> {
    const sendToGA = (object) => {
        if(typeof dataLayer === 'object' && typeof dataLayer.push === 'function') {
            dataLayer.push(object);
        }
    }

    if (!vendor) {
        sendToGA(info);
    } else {
        // waitUntilUserConsent(vendor)
        checkUserConsentForPropose()
            .then(isAccepted => {
                if(isAccepted) {
                    sendToGA(info);
                }
            })
            .catch(_ => {})
    }
};

// export const loadGTMPixel = () => {
//     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//             new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//         '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//     })(window,document,'script','dataLayer','GTM-7Z6F');
// }

export default () => {
    document.addEventListener('click', (e) => {
        const target = event.target;
        const parent = target.parentNode;

        if (target.matches('a.no-tabbed.kriptomat, a.no-tabbed.kriptomat *')) {
            sendPush({
                'event': 'web-vitals',
                'event_category': 'kriptomat',
                'event_action': 'click'
            });
        }

        // Ha hecho click en el botón Tab de la gráfica interactiva
        if (target.matches('a#interactiveTab') || (parent && parent.matches('a#interactiveTab'))) {
            sendPush({
                'event': 'web-vitals',
                'event_category': 'interactive-tab',
                'event_action': 'click',
            });
        }

        // Ha abierto el buscador.
        if (target.matches('a.search-button') || (parent && parent.matches('a.search-button'))) {
            sendPush({
                'event': 'web-vitals',
                'event_category': 'search',
                'event_action': 'attempt'
            });
        }

        // Ha hecho click en alguna búsqueda.
        if (target.matches('a.topsearchresult') || (parent && parent.matches('a.topsearchresult'))) {
            const item = target.matches('a.topsearchresult') ? target : parent;
            sendPush({
                'event': 'web-vitals',
                'event_category': 'search',
                'event_action': 'click',
                'event_label': item.getAttribute('href') || '(not-set)'
            });
        }

        return true;
    });
};
