import {loadCommon} from './sections/index-common'
import {dispatchEvent} from "./utils/eventDispatch";

const detectPageType = async () =>
{
    // document.querySelectorAll('.wide-ad').forEach(item => {
    //     if (googletag) {
    //         googletag.cmd.push(function() {googletag.display(item.id); });
    //     }
    // });

    const body = document.querySelector('body');
    const type = body.dataset.type;

    await loadCommon();

    switch (type) {
        case "home":
            const home = await import(/*webpackChunkName: "home"*/ './sections/index-home');
            home.loadHome();
            break;
        case 'list':
            const list = await import(/*webpackChunkName: "list"*/'./sections/index-list');
            list.loadList();
            break;
        case 'quotes':
            const quotes = await import(/*webpackChunkName: "quotes"*/'./sections/index-quotes');
            quotes.loadQuotes();
            break;
        case 'stockdetail':
            const stockDetail = await import(/*webpackChunkName: "stockdetail"*/'./sections/index-stock-detail');
            stockDetail.loadStockDetail();
            break;
        case 'forum':
            const forum = await import(/*webpackChunkName: "forum"*/'./sections/index-forum');
            forum.loadForum();
            break;
        case 'users':
            const users = await import(/*webpackChunkName: "users"*/'./sections/index-user');
            users.loadUsers();
            break;
        case "indicators":
            const indicators = await import(/*webpackChunkName: "indi"*/'./sections/index-indicators');
            indicators.loadIndicators();
            break;
        case 'monitorTrading':
            const monitor = await import(/*webpackChunkName: "monitorTrading"*/'./sections/index-monitor-trading.es6');
            monitor.monitorTrading();
            break;
        case 'premium':
            const homePremium = await import(/*webpackChunkName: "premium"*/ './sections/index-premium');
            homePremium.homePremium();
            break;
// ###################  Páginas
        case "newsDetailPage":
            const newsDetail = await import(/*webpackChunkName: "ndPage"*/ './pages/newsDetailPage');
            newsDetail.newsDetailPage();
            break;
        case 'labolsahoyPage':
            const lbh = await import(/*webpackChunkName: "lbh"*/'./pages/laBolsaHoyPage');
            lbh.loadLBHPage();
            break;
        case 'tradingZonePage':
            const ztP = await import(/*webpackChunkName: "ztP"*/'./pages/zonaTradingPage');
            ztP.loadZTPage();
            break;
        case 'calendarPage':
            const calPage = await import(/*webpackChunkName: "cP"*/'./pages/calendarPage');
            calPage.calendarPage();
            break;
        case 'dictionaryPage':
            const dictionaryPage = await import(/*webpackChunkName: "dP"*/'./pages/dictionaryPage');
            dictionaryPage.loadDictionary();
            break;
        case 'brokerRecommendsPage':
            const brPage = await import(/*webpackChunkName: "brP"*/'./pages/brokerRecommendsPage');
            brPage.brokerRecommendsPage();
            break;
        case 'multimediaPage':
            const mPage = await import(/*webpackChunkName: "mmP"*/'./pages/multimediaPage');
            mPage.multimediaPage();
            break;
        case 'magazinePage':
            const mgPage = await import(/*webpackChunkName: "mgP"*/'./pages/magazinePage');
            mgPage.magazinePage();
            break;
        case 'myFavsPage':
            const mfPage = await import(/*webpackChunkName: "mFP"*/'./pages/myFavsPage');
            mfPage.myFavorites();
            break;
        case 'courseHome':
            const courseHomePage = await import(/*webpackChunkName: "cHome"*/'./pages/courseHome');
            courseHomePage.loadCourseHome();
            break;
        case 'courseDetailPage':
            const cdPage = await import(/*webpackChunkName: "cdP"*/'./pages/courseDetailPage');
            cdPage.courseDetailPage();
            break;
        case 'myBulletinPage':
            const mbP = await import(/*webpackChunkName: "mbP"*/'./pages/myBulletinPage');
            mbP.myBulletinPage();
            break;
        case 'especialesTagPage':
            const especiales = await import(/*webpackChunkName: "etP"*/'./pages/especialesTagPage');
            especiales.especialesTagPage();
            break;
        case 'errorPage':
            const error = await import(/*webpackChunkName: "errorPage"*/'./pages/errorPage');
            error.loadErrorPage();
            break;
        case 'none':
            //Do nothing
            break;
        default:
            console.error('You must define pageType block');
            throw new Error('pageType not detected');
    }

    return true;
}

detectPageType().then(() => {
    dispatchEvent('JSLoaded');
    console.log('Web loaded');
});
