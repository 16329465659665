import SuperStorageLocal from './superStorageLocal';
import SuperStorageSession from "./superStorageSession";

/**
 * SuperStorage es una abtracción de almacén de datos en la zona cliente que, de momento, permite utilizar o
 * localStorage o sessionStorage con funciones añadidas como darles un período de expiración.
 */
export default class SuperStorage
{
    constructor(storageType)
    {
        switch(storageType){
            case 'local': this.storage = new SuperStorageLocal(); break;
            case 'session': this.storage = new SuperStorageSession(); break;
            default: throw 'Storage type "'+storageType+'" is unknown';
        }
    }

    /**
     * Establece un nuevo objeto en el almacén
     * @param {string} itemName Clave de guardado
     * @param {any} item Cualquier valor que se desee salvar
     * @param {object} itemOptions Opciones de guardado
     * @return {void}
     */
    setItem(itemName, item, itemOptions)
    {
        if(typeof itemOptions !== 'object') itemOptions = {};

        const json = {
            value: item,
            expiration: (itemOptions.hasOwnProperty('expiration') && typeof itemOptions.expiration === 'number') ? itemOptions.expiration : null
        };
        this.storage.setItem(itemName, json);
    }

    /**
     * Obtiene el elemento del almacen y lo devuelve al usuario (siempre que no esté expirado)
     * @param {string} itemName Key de guardado
     * @return {null|*} El objeto guardado en el almacén o null si no se encontró o está expirado
     */
    getItem(itemName)
    {
        const elem = this.storage.getItem(itemName);
        if(!elem) return null;

        if(elem.hasOwnProperty('expiration') && elem.expiration !== null){
            const now = (new Date()).getTime();
            if(now > elem.expiration){
                this.removeItem(itemName);
                return null;
            }
        }

        return elem.value;
    }

    /**
     * Elimina un objeto del almacén
     * @param {string} itemName Key de guardado
     * @return {void}
     */
    removeItem(itemName)
    {
        this.storage.removeItem(itemName);
    }

}
