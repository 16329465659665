import {getConsentTCString} from "./consent";
import {loadHtmlScript} from "../utils/loadHtmlScript";

let stickyAdHandlerFn = null,
    bottomAdHandlerFn = null,
    adsSelectorQuery = null;

/**
 * @param {string} adsSelector
 * @param {(number) => void }stickyHandler
 * @param {() => void} bottomHandler
 * @return {Promise<unknown>}
 */
export const configureAds = ({adsSelector, stickyHandler, bottomHandler}) => {
    stickyAdHandlerFn = stickyHandler;
    bottomAdHandlerFn = bottomHandler;
    adsSelectorQuery = adsSelector;

    return new Promise((resolve, reject) => {
        getConsentTCString().then(consentString => {
            if (sas && sas.setGdprConsentData) {
                sas.setGdprConsentData(consentString);
            }

            return resolve();
        })
    });
}

/**
 * @param {HTMLElement} item
 * @param {number} width
 * @return {{format: string, does_holder_exists: boolean}|null}
 */
const getAdHolderFormat = (item, width) => {
    const isMultiSize = item.parentNode && item.parentNode.classList.contains('multiAd');
    let formatId, does_exists = !isMultiSize;
    if (item.parentNode && item.parentNode.classList.contains('multiAd')) {
        formatId = width < 1024 ? item.dataset.adsSmall : item.dataset.adsBig
        if (!formatId) {
            return null
        }
    } else {
        formatId = item.dataset.adsFormatid
    }

    return {
        format: formatId,
        does_holder_exists: does_exists
    }
}

export const reloadAds = () => {
    if (!adsSelectorQuery) {
        console.warn('Ads Selector not found');
        return;
    }

    const adHoldersNodes = document.querySelectorAll(adsSelectorQuery + ':not(.ad-done)');
    const adHolders = Array.from(adHoldersNodes);

    if (!adHolders.length) {
        console.warn('No ads holder');
        return;
    }

    const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth

    const adsByPage = adHolders.reduce((acc, item) => {
        const pageId = item.dataset.adsPageid;
        if (!pageId) {
            return acc;
        }

        const format = getAdHolderFormat(item, width);
        if (!format || !format.format) {
            return acc;
        }

        if (!acc[pageId]) {
            acc[pageId] = [];
        }

        acc[pageId].push(format.format);
        return acc;
    }, {});

    const tagsElements = Array.from(document.querySelectorAll('.sasAdTag'));
    const adsTags = tagsElements.reduce((acc, item) => {
        const tag = item.dataset.sastag;
        if (tag) acc.push(tag);
        return acc;
    }, []);

    const pagesId = Object.keys(adsByPage);
    pagesId.forEach((item) => _initSasOneCall(item, adsByPage[item], adsTags))


    adHolders.forEach(item => {
        const format = getAdHolderFormat(item, width);
        if (!format || !format.format) {
            return null;
        }

        const formatId = format.format;
        if (!format.does_holder_exists) {
            //Remove previous instances of inner sas ads.
            const expectedAdHolderId = 'sas_' + formatId;
            document.querySelectorAll('#'+expectedAdHolderId).forEach(it => {
                it.id = expectedAdHolderId + '_' + Date.now();
            });

            const adHolder = document.createElement('div')
            adHolder.setAttribute('id', expectedAdHolderId)
            item.appendChild(adHolder)
        }

        item.classList.add('ad-done');
        try {
            renderAdHolder(+format.format);
        } catch (err) {
            console.error('Error while loading {' + pageId + ',' + formatId + '}: ' + err);
        }
    });
}

export const loadIntersticial = async () => {
    if (!window.location.pathname.startsWith('/premium')) {
        await loadHtmlScript('https://adgage.adkaora.space/estrategias-de-inversion/generic/prod/adk-init.js')
    }
}

const _initSasOneCall = (pageId, formatArray, tags) => {
    // https://help.smartadserver.com/articles/Knowledge/Tagging-guide-2-implementation
    console.log(pageId, formatArray, tags);
    sas.cmd.push(() => {
        sas.call("onecall", {
            siteId: 418170,
            pageId: pageId,
            formats: formatArray.map(item => ({id: item})),
            target: tags.join(','),
            schain: '1.0,1!ads4you.es,418170,1,,'
        }, {
            onAd: (data) => {
                console.log(data)
            },
            onNoad: function(x) {
                document.querySelector('#sas_' + x.formatId).classList.remove('ad-done');
            }
        });
    });
}


const renderAdHolder = (formatId) => {
    sas.cmd.push(() => {
        sas.render(formatId);
    });

    switch (formatId) {
        case 102580:
        case 102581:
            stickyAdHandlerFn && stickyAdHandlerFn(formatId);
            break;
        case 102579:
            bottomAdHandlerFn && bottomAdHandlerFn();
            break
    }
}
