import {intlTelInputBuilder} from "../intlTelInput/intlTelInput";
import {getUserData} from "../modulesUtils";

// Carga el comportamiento de la caja de suscripción de boletín
export default function(){
    import('./boletines.scss');

    return getUserData()
        .then(user => {
             replaceBulletin();
        })
        .catch(noUser => {
            return $(function(){
                $('div[data-bulletin]').each(function(){
                    init($(this));
                });
            });
        });
}

/**
 * Inicializa la función que define el comportamiento de la caja de boletín
 * @param {jQuery} element Selector de JQuery que representa el div general de la caja
 */
function init(element){
    const elementId = element.data('bulletin');

    //Cuando el usuario haga foco en un hijo de element cuyo id acabe en "_email".
    const emailSelector = element.find('[id$="_email"]');
    emailSelector.on('focus', function(e){
        $.ajax({
            url: unmaskpath(element.data('bulletin-token')),
            dataType: 'html',
            data: {'t': elementId},
            type: 'POST',
            cache: false,
            success: function(data) {
                element.find('[id$="_token"]').val($(data).val());
            }
        })
    });

    //Comportamiento al enviar el email
    const formSelector = element.find('[id$="-bulletin-form"]');
    formSelector.on('submit', function(e) {
        e.preventDefault();
        $.ajax({
            type: formSelector.attr('method'),
            url: unmaskpath(element.data('bulletin-handler')),
            data: formSelector.serialize(),
            cache:false,
            beforeSend: function(){
                $('#' + elementId + ' #bulletin-sending-label').show();
                formSelector.hide();
                emailSelector.removeClass('error')
                    .next("span").next('div').remove();
            },
            success: function(data) {
                if (data.modal) {
                    $('#modal-info').html(data.modal);
                    $("#trigger-modal").trigger('click');
                    $('.form-text.error div').hide();
                    $('.error').removeClass('error');
                }

                if (data.success && typeof data.redirect !== "undefined" ) {
                    emailSelector.val('');
                    $.ajax({
                        url: unmaskpath(element.data('bulletin-base')) + data.redirect,
                        success: function(html) {
                            const registrationSelector = $('#'+elementId+'-registration-form');
                            $('#' + elementId + ' #bulletin-sending-label').hide();
                            registrationSelector.html($(html).html());
                            registrationSelector.parent().show();

                            //Cargar la librería de giro y cuando esté...
                            flipBulletin(element)
                                //... pasar al comportamiento de usuario y teléfono.
                                // Se hace en función async aparte para solo entonces, cargar IntlTelInput
                                .then(() => register(element));
                        }
                    })
                } else {
                    $('#' + elementId + ' #bulletin-sending-label').hide();
                    formSelector.html(data.form).show();
                }
            },
        });
        return false;
    })
}

/**
 * Define la animación del giro del boletín. Se hace Async para importar la librería de giro cuando es necesaria
 * @param {jQuery} element Selector JQuery que representa la caja general de boletín
 * @return {Promise<void>}
 */
async function flipBulletin(element) {
    const elementId = element.data('bulletin');
    const flipAxis = ['normalBoletin','navBoletin','reNormalBoletin'].includes(elementId) ? 'y' : 'x';

    const flip = await import(/*webpackChunkName: "jqFlip"*/'../flip/jqueryFlip');

    //Declaramos el giro
    element.flip({
        axis: flipAxis,
        trigger: 'manual',
        autoSize: false,
        reverse: false
    });

}

/**
 * Define el comportamiento para cuando, una vez enviado el email, se carga la caja de nombre y teléfono.
 * Utiliza Async para cargar IntlTelInput
 * @param {jQuery} element Selector JQuery que representa la caja general de boletín
 * @return {Promise<void>}
 */
async function register(element)
{
    const $tel = $('input[id$="_tel');
    const elementId = element.attr('id');
    element.flip('toggle');

    const widget = await intlTelInputBuilder($tel);

    const registerFormSelector = $('#'+elementId+'-registration-form');
    registerFormSelector.on('submit', function(e){
        e.preventDefault();

        if (!widget.isValidNumber()) {
            return false;
        }

        //Establecer en el envío el teléfono y el prefijo en los campos correctos.
        const data = registerFormSelector.serializeArray().map(item => {
            if (item.name === 'RegisterUser[prefixMobile]') {
                return { ...item, value: widget.getSelectedCountryData().iso2};
            } else if(item.name === 'RegisterUser[mobilePhone]') {
                return {...item, value: widget.getNumber()};
            } else {
                return item;
            }
        })

        $.ajax({
            type: registerFormSelector.attr('method'),
            url: unmaskpath(element.data('bulletin-register')),
            data: $.param(data),
            cache: false,
            beforeSend: () => {
                registerFormSelector.hide();
                $('#'+elementId+' #bulletin-registration-sending-label').show();
            },
            success: function(data){
                if (data.modal) {
                    $('#modal-info').html(data.modal);
                    $("#trigger-modal").trigger('click');
                    $('.form-text.error div').hide();
                    $('.error').removeClass('error');
                }
                $('#'+elementId+' #bulletin-registration-sending-label').hide();
                if (data.success) {
                    $('#'+elementId+'-bulletin-form').show();
                    element.flip('toggle');
                    registerFormSelector.show();
                    $('.md-aux-bulletin.back').hide();
                } else {
                    registerFormSelector.html($(data).html()).show();
                }
            }
        });
        return false;
    });
}

/**
 * Elimina o reemplaza la caja de boletines por el módulo "alternativeToBulletin".
 * Sobretodo de cara a usuarios ya registrados.
 */
const replaceBulletin = () => {
    document.querySelectorAll('.md-bulletin').forEach(item => item.parentNode.removeChild(item));
    document.querySelectorAll('section.md-bulletin-alternative').forEach(item => item.style.display = 'block');
}
