import superStorageAbstract from "./superStorageAbstract";

class superStorageLocal extends superStorageAbstract
{
    /**
     * Obtiene un elemento JS del almacenamiento
     * @param {string} itemName Clave de guardado
     * @return {Object|null} Objeto encontrado o null en caso contrario
     */
    getItem(itemName){
        const plain = localStorage.getItem(itemName);
        if(!plain) return null;
        return this.jsonStringify2Object(plain);
   }

    /**
     * Guarda un objeto en el almacenamiento
     * @param {string} itemName Clave de guardado
     * @param {object} item Elemento a guardar
     */
    setItem(itemName, item)
    {
        const itemString = JSON.stringify(item);
        localStorage.setItem(itemName, itemString);
    }

    /**
     * Elimina un objeto del almacenamiento
     * @param {string} itemName Clave de guardado
     */
    removeItem(itemName)
    {
        localStorage.removeItem(itemName);
    }
}

export default superStorageLocal;
