const bitBanModules = [
    {classes: '.md-data-sheet', file: 'data-sheet.scss'},
    {classes: '.expert-opinion', file: 'expert-opinion.scss'},
    {classes: '.md-info-premium,.md-info-premium-3', file: 'info-premium.scss'},
    {classes: '.md-meeting', file: 'meeting.scss'},
    {classes: '.md-new-box', file: 'new-box.scss'},
    {classes: '.md-new-entry', file: 'new-entry.scss'},
    {classes: '.md-new-opinion', file: 'new-opinion.scss'},
    {classes: '.md-new-video', file: 'new-video.scss'},
    {classes: '.md-rel', file: 'rel.scss'},
    {classes: '.md-rules', file: 'rules.scss'},
    {classes: '.md-tools,.md-tool', file: 'tools.scss'},
    {classes: '.md-vision-global', file: 'vision-global.scss'},
    {classes: '.md-consulting-entry', file: 'consulting-entry.scss'},
    {classes: '.md-clients', file: 'clients.scss'},
    {classes: '.md-tab-detail-course', file: 'tab-detaile-couse.scss'},
    {classes: '.md-consulting-detail-course', file: 'consulting-detail-course.scss'},
    {classes: '.md-submit-question', file: 'submit-question.scss'},
    {classes: '.md-magazine, .md-magazine-cover', file: 'magazine.scss'},
    {classes: '.md-topmost', file: 'topmost.scss'},
    {classes: '.md-timeline', file: 'timeline.scss'},
    {classes: '.md-highlight', file: 'highlight.scss'},
]

/**
 *
 * @param {string} className
 */
const loadCSSFromClass = (className) => {
    bitBanModules.forEach(item => {
        const itemClasses = item.classes.split(",");
        if (itemClasses.includes('.' + className)) {
            const fileName = item.file.replace('.scss', '');
            import('./modules/' + fileName + '.scss').then(() => {});
        }
    });
}

export const loadBitbanModules = async () => {
    const IntersectionCapability = ('IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype);

    if (!IntersectionCapability) {
        await import('../utils/IntersectionObserverPolyfill');
        console.log('Loaded IntersectionObserver Polyfill');
    }

    const intersectionOptions = {
        rootMargin: '150px'
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.forEach(className => {
                    loadCSSFromClass(className);
                });
                observer.unobserve(entry.target);
            }
        });
    }, intersectionOptions);

    bitBanModules.forEach(item => {
        document.querySelectorAll(item.classes).forEach(el => observer.observe(el));
    });
}
