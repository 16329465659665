/**
 * Mediante un Intersection Observer, detecta la entrada en el viewport de los elementos
 * contenidos en el array elements y ejecuta el callback definido. Si el navegador no
 * soportase IntersectionObserver, los ejecutará inmediatamente.
 *
 * @param {NodeListOf<Element>} elements
 * @param {function(Element)} cb
 * @param {Object} options
 * @constructor
 */
export const EInterceptionObserver = (elements, cb, options = null) => {
    const rootMargin = options && options.rootMargin ? options.rootMargin : '200px';
    const keepObserving = options && options.keepObserving && options.keepObserving === true;

    try{
        const intersectionOptions = {
            rootMargin
        }

        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    cb(entry.target);
                    if (!keepObserving) {
                        intersectionObserver.unobserve(entry.target);
                    }
                }
            });
        }, intersectionOptions);

        elements.forEach(elem => intersectionObserver.observe(elem));

        return intersectionObserver;
    } catch(e) {
        console.warn('Your browser doesnt support Lazy Load. Update it now!');
        elements.forEach(elem => cb(elem));
        return null;
    }
};
