export const loadHtmlScript = (src, options = {}) => {
    options.place = options.place ?? 'head';
    options.options = options.options ?? {};

    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('async', 'async');
        script.setAttribute('src', src);

        for (const [key, value] of Object.entries(options.options)) {
            script.setAttribute(key, value);
        }

        script.onload = script.onreadystatechange = function() {
            if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
                script.onload = script.onreadystatechange = null;
                return resolve();
            }
        };

        script.onerror = function() {
            return reject(`Fail to load script ${src}`);
        }

        if (options.place === 'head') {
            document.head.appendChild(script);
        } else {
            document.body.appendChild(script);
        }
    });
}