/**
 * Función que funciona como la homónima de PHP
 * @param {string} number Número a formatear
 * @param {number} decimals Número de decimales a mostrar
 * @param {string} dec_point Dígito de separación decimal
 * @param {string} thousands_sep Dígito de separación de millares
 * @returns {string}
 */

export const number_format = function (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            const k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    let s = '';
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
};

/**
 * Función que redondea un número, de forma igual que toFixed, pero devuelve el número
 * en formato numérico.
 * @param {number} number Número a redondear
 * @param {number} decimals Número de decimales
 * @param {number} base Base de cálculo. Por defecto base 10 (decimal).
 */
export const toFixedNumber = function(number, decimals, base){
    if(!base) base = 10;
    const pow = Math.pow(base||10,decimals);
    return +( Math.round(number*pow) / pow )
};

/**
 * Decimales por defecto
 * @type {{vol: number, last: number, var: number, varPerc: number, contractVol: number}}
 */
export const defaultDecimals = {
    'last': 3,
    'vol': 2,
    'var': 3,
    'varPerc': 2,
    'contractVol': 0
};

/**
 * Función que, utilizando number_format, define el formateo de una cantidad a través del tipo de campo.
 * Si se define un tercer parámetro, tendrá en cuenta el número de decimales adjuntado.
 * @param {string} type Tipo de campo: last, vol, varperc, etc.
 * @param {number, string} value Valor a formatear
 * @param {number, string} decimal Número de decimales que tendrá el resultado.
 * @returns {string}
 */
export const customCotFormat = function(type, value, decimal){
    if(decimal && isNaN(decimal)){
        throw 'If a third parameter is attached, it must be a number';
    }
    switch (type){
        case 'last':
            return number_format(value, (decimal ? decimal : defaultDecimals['last']) , ',', '.');
        case 'vol':
            return number_format(value, (decimal ? decimal : defaultDecimals['vol']), ',', '.');
        case 'varPerc':
            if(value < 0){
                return number_format(value, (decimal ? decimal : defaultDecimals['varPerc']), ',', '.') + '%';
            }else if(value > 0){
                return number_format(value, (decimal ? decimal : defaultDecimals['varPerc']), ',', '.') + '%';
            }else{
                return number_format(value, (decimal ? decimal : defaultDecimals['varPerc']), ',', '.') + '%';
            }
        case 'var':
            if(value < 0){
                return number_format(value, (decimal ? decimal : defaultDecimals['var']), ',', '.');
            }else if(value > 0){
                return number_format(value, (decimal ? decimal : defaultDecimals['var']), ',', '.');
            }else{
                return number_format(value, (decimal ? decimal : defaultDecimals['var']), ',', '.');
            }
        case 'contractVol':
            return number_format(value, (decimal ? decimal : defaultDecimals['contractVol']), ',', '.');
        case 'date':
            const date = new Date(value);
            return date.toLocaleTimeString();
        default:
            return 'Not available';
    }
};

/**
 * Función utilizada para dar estilo al valor
 * @param {number, string} value Valor a formatear
 * @returns {string}
 */
export const getColorFormat = function getColorFormat(value) {
    let css;
    const valueFloat = parseFloat(value);
    if (valueFloat < 0) {
        css = 'valueDown';
    } else if (valueFloat > 0) {
        css = 'valueUp';
    } else {
        css = 'value0';
    }
    return css;
};

/**
 * Función utilizada para trabajar las clases del dom en el streaming
 * @param {number} value Valor a formatear
 * @param {string} type Tipo de elemento sobre el que se aplican las actualizaciones
 * @param {object} $domElement objeto dom a tratar
 * @returns {string}
 */
export const getDomCss = function getDomCss(value, type, $domElement) {
    const valueFloat = parseFloat(value);
    if (type === 'var' || type === 'varPerc') {
        if (valueFloat < 0) {
            if ($domElement.hasClass('valueUp')) $domElement.removeClass('valueUp');
            if ($domElement.hasClass('value0')) $domElement.removeClass('value0');
            $domElement.addClass('valueDown');
            //css = 'valueDown';
        } else if (valueFloat > 0) {
            if ($domElement.hasClass('valueDown')) $domElement.removeClass('valueDown');
            if ($domElement.hasClass('value0')) $domElement.removeClass('value0');
            $domElement.addClass('valueUp');
            //css = 'valueUp';
        } else {
            if ($domElement.hasClass('valueDown')) $domElement.removeClass('valueDown');
            if ($domElement.hasClass('valueUp')) $domElement.removeClass('valueUp');
            $domElement.addClass('value0');
        }
    }
};

/**
 * Elimina duplicados en un array. Por cada iteración, detecta si la primera aparición
 * es menor que el índice actual.
 * @param original
 * @return {*}
 */
export const removeDuplicates = function(original){
    //Al señor IE 11 no le gusta la forma contraída
    return original.filter(function(item, index){return original.indexOf(item) >= index});
};