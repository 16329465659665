import './videoLive.scss';
import {template, smallPreviewTemplate} from './videoLivePreviewTemplate';
import SuperStorage from "../superStorage/superStorage";
import {sendPush} from "../../marketing/gtm";
import {getUserData} from "../modulesUtils";

const storage = new SuperStorage('session');

/**
 * Find element parents called <tag>. Return first <tag> element or null if not found.
 * @param {Node|null} element
 * @param {string} tag Tag name
 * @return {HTMLElement|null}
 */
const findElementRecursive = (element, tag) => {
    if (!element) {
        return null;
    }
    return element.nodeName === tag.toUpperCase() ? element : findElementRecursive(element.parentNode, tag);
}


const fetchLive = async (originUrl) => {
    try{
        let response = await fetch(originUrl);
        const lives = await response.json();

        if (!lives || !lives.length) {
            return null;
        }

        return lives[0];
    } catch (err) {
        console.error(err);
    }
}

const isLivePreviewEnabled = () => null === storage.getItem('videolive-disable');

const disableLivePreview = (blockTTL = 86_400_000) => {
    const expiration = (new Date()).getTime() + blockTTL
    storage.setItem('videolive-disable', 1, {expiration});
}

const drawSmallIcon = (url) =>
    document.querySelectorAll('.headerLive__container').forEach(el =>
        el.innerHTML = smallPreviewTemplate(url)
    );

const sendGAEvent = (action) => sendPush({
    'event' : 'web-vitals',
    'event_category' : 'livePreview',
    'event_action' : action
});


const drawInterface = ({vimeoId, url, youtubeUrl}) => {
    const liveHolder = document.querySelector('.bottomFixed');
    liveHolder.innerHTML = template({url, vimeoId, youtubeUrl});

    document.addEventListener('click', (e) => {
        const target = e.target;
        const firstLink = findElementRecursive(target, 'a');

        if (!firstLink) {
            return;
        }

        if (firstLink.matches('.bottomLive__closeLink')) {
            e.preventDefault();
            sendGAEvent('close');
            disableLivePreview(86_400_000); //24h
            liveHolder.parentNode.removeChild(liveHolder);
        }

        if (firstLink.matches('.headerLive__link')){
            sendGAEvent('follow');
            disableLivePreview(600_000); //10 minutos
        }

    });
}

const drawWhenScroll = (params) => {
    const SCROLL_THRESHOLD = 200;
    const handler = () => {
        const current = window.scrollY || window.pageYOffset;
        if (current > SCROLL_THRESHOLD) {
            drawInterface(params);
            window.removeEventListener('scroll', handler);
        }
    }

    window.addEventListener('scroll', handler, {passive: true});
}

export default function(){
    window.addEventListener('load', async () => {
        //Search lives url in DOM
        const holder = document.querySelector('.headerLive__container');
        if (!holder || !holder.dataset?.url) {
            return;
        }

        //Fetch lives
        const {id:vimeoId, url, youtube:youtubeUrl} = await fetchLive(holder.dataset.url) || {};
        if (!vimeoId || !url) {
            return;
        }

        drawSmallIcon(url);

        //Do not show live if user has disabled it.
        if (!isLivePreviewEnabled()) {
            return;
        }

        //Do not show live if user is premium and is viewing the live url.
        const liveUrl = new URL(url);
        if (liveUrl.pathname === window.location.pathname) {
            getUserData()
                .then(a => {
                    const isPremiumUser = a && a.premium;
                    if (!isPremiumUser) {
                        drawWhenScroll({vimeoId, url, youtubeUrl})
                    }
                })
                .catch(_ => drawWhenScroll({vimeoId, url, youtubeUrl}));
        } else {
            drawWhenScroll({vimeoId, url, youtubeUrl});
        }
    })
}
