import {sendPush} from "./gtm";

const GOOGLE_ADVERTISING_VENDOR = 'google';

//Eventos de sistema:
// EiRegisterTry : Se ha empezado el proceso de registro en Ei.
// EiRegisterOK :  Ha finalizado con éxito un registro en Ei.
// EiRegisterConfirmed :  Se ha validado con éxito un registro en Ei.

// EiSubscriptionTry : Se ha empezado el proceso de suscripción.
// EiSubscriptionOK :  Ha finalizado con éxito una suscripción.

export default function() {
    document.addEventListener('JSLoaded', () => {
        sendPush({'event': 'google-ads-view',}, GOOGLE_ADVERTISING_VENDOR);
    });

    document.addEventListener('EiRegisterTry', () => {
        // console.log('Comienza el registro');
    });

    document.addEventListener('EiRegisterOK', (e) => {
        const {email, id, name, phone} = e.detail;
        sendPush({'event': 'google-ads-register', 'user_email': email, 'user_phone': phone}, GOOGLE_ADVERTISING_VENDOR);
    });

    document.addEventListener('EiRegisterConfirmed', (e) => {
        // console.log('Se validó el registro', e);
    });

    // Registra cuando se produce una suscripción en Ei. Lanzado en marketing/xx/success.html.twig
    // Asociar todas las acciones necesarias desde aquí
    document.addEventListener('EiSubscriptionOK', (event) => {
        /*
        subscription: {
                id: {{ subscription.idSuscription }},
                isRate: {{ subscription.idPrice is not null ? 1 : 0 }},
                renovation: {{ subscription.renovation == true ? 1 : 0 }},
                status: {{ subscription.status }},
                magazine: {{ subscription.hasMagazine() ? 1 : 0 }}
            },
            rate: {
                id: {{ isRate ? subscription.idPrice.idRate.idRate : subscription.idPromotion.idPromotion }},
                name: "{{ isRate ? subscription.idPrice.idRate.description : subscription.idPromotion.description  }}",
                price: {{ isRate ? subscription.idPrice.price : subscription.idPromotion.price }},
                currency: "{{ isRate ? subscription.idPrice.currency.code : subscription.idPromotion.currency.code }}",
            },
            user: {
                id: {{ userData.idUser }},
                name: "{{ userData.name }}",
                email: "{{ userData.email }}",
                phone: "{{ userData.mobilePhone }}"
            },
            dates: {
                premiumDate: "{{ subscription.premiumDate | date('c') }}",
                startDate: "{{ subscription.startDate | date('c') }}",
                endingDate: "{{ subscription.endingDate | date('c') }}",
            },
         */
        const {email, id, name, phone} = event.detail?.user || {};

        sendPush({'event': 'google-ads-subscription', 'user_email': email, 'user_phone': phone}, GOOGLE_ADVERTISING_VENDOR);
    });
}