import {sendContentGroup} from "./gtm";

export const sendContentGroups = () => {
    document.querySelectorAll('.ga-content').forEach(el => {
        const contentGroupType = el.dataset.contentType;
        const contentGroupId = el.dataset.contentId;
        if (contentGroupType && contentGroupId) {
            sendContentGroup(contentGroupType, contentGroupId);
        }
        el.remove();
    });
}