/**
 * Hace flotante la publicidad SAS del borde inferior extremo de la página (sticky 79)
 */

export const makeFloatingBottomSticky = () => {
    let btnAdClose = document.querySelector('.ad-sticky-close');
    const onScroll = () => {
        requestAnimationFrame(() => {
            const scroll = document.documentElement.scrollTop;

            if (scroll > 150) {
                document.querySelector('.sticky-ad').classList.add('show-sticky-ad');
            } else {
                document.querySelector('.sticky-ad').classList.remove('show-sticky-ad');
            }
        });
    }

    if (btnAdClose) {
        btnAdClose.addEventListener('click', function (e) {
            e.target.parentNode.classList.remove('show-sticky-ad');
            window.removeEventListener('scroll', onScroll);
        });
        window.addEventListener('scroll', onScroll, {passive: true});
    }
}