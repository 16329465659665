class superStorageAbstract
{
    /**
     * Obtiene un elemento JS del almancenamiento
     * @param {string} itemName Clave de guardado
     * @return {Object|null} Objeto encontrado o null en caso contrario
     */
    getItem(itemName){
        throw 'Not implemented';
   }

    /**
     * Guarda un objeto en el almacenamiento
     * @param {string} itemName Clave de guardado
     * @param {object} item Elemento a guardar
     */
    setItem(itemName, item)
    {
        throw 'Not implemented';
    }

    /**
     * Elimina un objeto del almacenamiento
     * @param {string} itemName Clave de guardado
     */
    removeItem(itemName)
    {
        throw 'Not implemented';
    }

    /**
     * Convierte una cadena de texto, a priori una cadena JSON, a un objeto. Si hubiese algún error,
     * devolverá null (al no poder convertirse).
     * @param {string} string
     * @return {null|string}
     */
    jsonStringify2Object(string){
        try{
            return JSON.parse(string);
        }catch(e){
            return null;
        }
    }
}

export default superStorageAbstract;