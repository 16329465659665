import {loadAlertifyAsync} from "../alertify/alertify";
import './favoritos.scss';
import {sendPush} from "../../marketing/gtm";
import {EInterceptionObserver} from "../EIntersectionObserver/EIntersectionObserver";

const followCode = (action) => {
    sendPush({
        'event' : 'web-vitals',
        'event_category' : 'favoritesBtn',
        'event_action' : action
    });
}

export default ()=>{
    //Cargar el HTML de los botones de favoritos en páginas comunes
    document.querySelectorAll('div.box-favorite[data-idticker]')
        .forEach(favoriteBox => loadHTMLFavoriteBtn(favoriteBox));

    loadNewsAlerts();

    window.addEventListener('click', (event) => {
        const currentNode = event.target;
        const parent = currentNode.parentNode;

        if (currentNode.matches('.btn-favorites-unlogin') || (parent && parent.matches('.btn-favorites-unlogin'))) {
            event.preventDefault();
            app.$('.user-session-button').trigger('click');
            followCode('attempt');
            return;
        }
        //Botón genérico de añadir
        if (currentNode.matches('[id^="add-favorite-button"]') || (parent && parent.matches('[id^="add-favorite-button"]'))) {
            followCode('follow');
            return;
        }
        //Botones de eliminar de favoritos genéricos.
        if (currentNode.matches('.btn-favorites--delete') || (parent && parent.matches('.btn-favorites--delete'))) {
            event.preventDefault();
            event.stopPropagation();
            deleteFavorite( (currentNode.tagName.toLowerCase() === 'span' ? parent: currentNode));
            followCode('unfollow');
            return;
        }

        //Botones de eliminar de favoritos en el módulo top-bottom y del listado de favoritos
        if (currentNode.matches('a.delete-favorite') || (parent && parent.matches('a.delete-favorite'))) {
            event.stopPropagation();
            event.preventDefault();
            deleteFavorite(currentNode);
            followCode('unfollow');
            return;
        }
    });
};

const io = EInterceptionObserver([], (target) => {
    target.classList.add('enlaceAlerta--done');
    loadHTMLFavoriteBtn(target, true)
});

/**
 * Carga de forma asíncrona las alertas en las noticias mediante IO si está disponible; Si no existe el IO,
 * las carga en el momento.
 */
export const loadNewsAlerts = () => {
    const items = document.querySelectorAll('div.enlaceAlerta:not(.enlaceAlerta--done)');
    items.forEach(item => {
        if (io) {
            io.observe(item)
        } else {
            loadHTMLFavoriteBtn(item, true);
        }

    });
}

/**
 * Crea el contenedor de la caja de favoritos dentro de un artículo de noticia.
 * En la caja se incluye el botón de control de favoritos y la zona premium: noticias de AF y el enlace a Indicadores
 * @param {string} innerHTML Contenido HTML de la caja
 * @return {HTMLElement}
 */
const createNewsBoxHolder = (innerHTML) => {
    const div = document.createElement('div');
    div.classList.add('box-favorite', 'box-favorite-news');
    div.innerHTML = innerHTML;
    return div;
}
/**
 * Busca en la caja de favoritos por si hubiese una alerta de favoritos. En este caso, carga la
 * utilidad Alertify en diferido y muestra la advertencia.
 * @param responseHtml
 * @return {null}
 */
const parseFlashMessageInResponse = (responseHtml) => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(responseHtml, 'text/html');
    const flashMessages = dom.querySelectorAll('.favorite-flash');
    if (!flashMessages || !flashMessages.length) {
        return null;
    }

    loadAlertifyAsync()
        .then(alertify => {
            flashMessages.forEach(flash => {
                switch (flash.dataset.type) {
                    case 'error':
                        alertify.error(flash.dataset.message);
                        break;
                    case 'notice':
                        alertify.success(flash.dataset.message);
                        break;
                }
            });
        });
}

/**
 * Busca las noticias de análisis fundamental dentro del HTML recibido y detecta si hay más de una.
 * Si esto ocurre, elimina la que tenga el data-id igual que la noticia actual que está viendo el visitante.
 * @param {string} responseHtml
 */
const removeFANews = (responseHtml) => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(responseHtml, 'text/html');
    const faNews = dom.querySelectorAll('div.premium_items a[data-id]');

    if (!faNews || !faNews.length || faNews.length < 2) {
        return responseHtml;
    }

    const regexIdNews = location.pathname.match(/-n-(?<idNews>\d{5,})/)
    if (!regexIdNews || !regexIdNews.groups || !regexIdNews.groups['idNews']) {
        return responseHtml;
    }


    faNews.forEach(news => {
        if (news.dataset.id && news.dataset.id === regexIdNews.groups['idNews']) {
            news.remove();
        }
    });

    return dom.documentElement.innerHTML;
}

/**
 * Realiza una petición al servidor y devuelve el HTML correspondiente a la caja de favoritos,
 * incluyendo este HTML recibido dentro de la propia caja
 * @param {HTMLElement} box
 * @param {boolean} newsBox Determina si es un botón dentro de un artículo de noticia
 */
const loadHTMLFavoriteBtn = (box, newsBox = false) => {
    const idTicker = box.dataset.idticker;
    const style = box.dataset.style

    let url = newsBox
     ? `${unmaskpath(JSVAR.base_url)}/cotizaciones-boton-favorito/${idTicker}?style=news`
     : `${unmaskpath(box.dataset.url)}/${idTicker}?style=${style}`;

    fetch(url, {
        method: 'POST',
        body: {'style': style},
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
        .then(response => response.text())
        .then(response => {
            //Antes de pintar la caja, se elimina la noticia de AF duplicada
            parseFlashMessageInResponse(response);
            response = removeFANews(response)
            if (newsBox) {
                box.append(createNewsBoxHolder(response));
            } else {
                //Comprobamos en la respuesta (el HTML de la caja), si existen mensajes flash que mostrar
                box.innerHTML = response
            }
        })
        .catch(error => {
            box.parentNode.removeChild(box);
            console.error(error);
        })
}

/**
 * Asigna el comportamiento al botón de eliminar favorito cuando el usuario ya lo tiene incluido
 * @param {HTMLElement} aBtn
 */
const deleteFavorite = (aBtn) => {
    const url = aBtn.dataset.href;
    if (!url) {
        return
    }

    loadAlertifyAsync()
        .then(alertify => {
            alertify
                .confirm('¿Realmente quieres eliminar este valor de tus favoritos?', function(){
                    location.href = unmaskpath(url);
                });
        })
        .catch(err => {
            location.href = unmaskpath(url);
        });
}
