//Dummy init.
window.didomiOnReady = window.didomiOnReady || [];
window.didomiOnReady.push(function (Didomi) {
    Didomi.on('consent.changed', function (context) {
        console.log('consent.changed');
        const event = new CustomEvent('Didomi:consent.changed', {
            bubbles: true,
            cancelable: true,
            detail: context
        });
        document.dispatchEvent(event);
    });
});

/**
 * Determines whether it is a partial response; i.e., the user has not chosen a consent.
 * @return {Promise<boolean>}
 */
const checkUserIsPartial = () => {
    return new Promise((resolve) => {
        window.didomiOnReady.push(function (Didomi) {
            return resolve(Didomi.isUserStatusPartial());
        });
    });
}

/**
 * Didomi has deprecated "getUserConsentStatusForPurpose" method.
 * This is an internal method that returns the internal state of some purpose.
 * Does not consider whether the user has decided or is the default state.
 * @param {string} purpose_id
 * @return {Promise<boolean>}
 */
const getUserConsentStatusForPurpose = (purpose_id) => {
    return new Promise((resolve) => {
        window.didomiOnReady.push(function (Didomi) {
            const status = Didomi.getCurrentUserStatus();
            if (!status || !('purposes' in status)) {
                return resolve(null);
            }
            const enabled = purpose_id in status.purposes && status.purposes[purpose_id].enabled;
            return resolve(enabled);
        });
    });
}

/**
 * Wait until the user accepts consent for a purpose. Once the user CHOOSES and ACCEPTS, it launches the cb argument.
 * @param {string} topic
 * @param {function} cb
 */
export const waitUserConsentForPropose = (topic = 'cookies', cb) => {
    checkUserIsPartial()
        .then(isPartial => {
            return getUserConsentStatusForPurpose(topic).then(consent => {
                if (false === isPartial && true === consent) {
                    if (cb) cb();
                    return true; // Successful finished
                }
                return false; // We should continue with promise chain
            });
        })
        .then(executionCompleted => {
            if (executionCompleted) {
                return; // Successful finished
            }

            const waitForConsentChange = new Promise((resolve) => {
                document.addEventListener('Didomi:consent.changed', () => {
                    getUserConsentStatusForPurpose(topic).then(newConsent => {
                        if (newConsent === true) {
                            resolve(newConsent);
                        }
                    });
                }, {once: true});
            });

            waitForConsentChange.then(newConsent => {
                if (cb && newConsent === true) {
                    cb();
                }
            });
        })
}

/**
 * Determines a user's consent for a certain purpose when the user has made a choice.
 * Default consent it is not considered.
 * @param {string} topic
 * @return {Promise<bool>}
 */
export const checkUserConsentForPropose = (topic = 'cookies') => {
    return new Promise((resolve) => {
        checkUserIsPartial()
            .then(isPartial => {
                if (false === isPartial) {
                    return getUserConsentStatusForPurpose(topic)
                        .then(x => resolve(x))

                }
                else {
                    document.addEventListener('Didomi:consent.changed', () => {
                        return getUserConsentStatusForPurpose(topic)
                            .then(x => resolve(x));
                    }, {once: true});
                }
            });
    })
    ;
}

/**
 * Waits until the user has accepted custom vendor
 * @param {string} vendor
 * @return {Promise<true>}
 */
export const waitUntilUserConsent = (vendor) => {
    return new Promise((resolve) => {
        window.didomiOnReady.push(function (Didomi) {
            Didomi.getObservableOnUserConsentStatusForVendor(vendor)
                .filter(function (status) {
                    return !!status
                })
                .subscribe(function (consentStatus) {
                    return resolve(consentStatus);
                });
        });
    });
}

/**
 * Waits until the user has accepted/rejected vendors and broadcasts the token string
 * @return {Promise<String>}
 */
export const getConsentTCString = () => {

    return new Promise((resolve) => {
        if (!__tcfapi) {
            console.warn('No __tcfapi');
            return resolve();
        }
        window.didomiOnReady.push(function (Didomi) {
            const {consent_string} = Didomi.getCurrentUserStatus();
            return resolve(consent_string);
        });
    });
}