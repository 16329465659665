import "./search.scss";
import {loadHtmlScript} from "../../utils/loadHtmlScript";

const getElements = () => {
    const searchButton = document.querySelector(".search-button");
    const searchLayer = document.querySelector("#search-layer");

    return [searchButton, searchLayer];
}

export const removeSearchBox = () => {
    const [searchButton] = getElements();
    searchButton.remove();
}

export const search = () => {
    loadHtmlScript('https://cse.google.com/cse.js?cx=8460a58623fc843d3');

    const [searchButton, searchLayer] = getElements();

    $(document).on('lity:ready', function(e, $instance) {
        const isSearchBox = $instance.find('#search-layer').length > 0;

        if (isSearchBox) {
            $instance.addClass('lity-search-theme');
            searchLayer.children[0].classList.remove('lity-hide');
            const secondSearchInput = searchLayer.querySelector("input");
            secondSearchInput.focus();
        }

    });

    // $(document).on('lity:open', function(e, $instance) {
    //
    //
    // });

    searchButton.addEventListener("click", (e) => {
        e.preventDefault();
        
        const lightbox = lity();
        
        lightbox(searchLayer);
    });
};