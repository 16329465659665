const CLASS_NAME = 'smartfollow';

export default function(){
    document.addEventListener('DOMContentLoaded', () => {
        bubbleFollowDataHrefLink();
    });
};

function bubbleFollowDataHrefLink()
{
    document.addEventListener('click', e => {
        const target = e.target;
        const parent = target.parentNode ? target.parentNode : null;

        const isTargetSmart = target.classList.contains(CLASS_NAME) && target.hasAttribute('data-href');
        const isParentSmart = parent ? parent.classList.contains(CLASS_NAME) && parent.hasAttribute('data-href') : false;
        const node = isTargetSmart ? target : parent;

        if (isTargetSmart || isParentSmart) {
            window.location = unmaskpath(node.dataset.href);
            event.stopPropagation();
            event.preventDefault();
            return false;
        }
    });
}