/**
 * Una vez importada la librería Sticky Kit desde globals.js (app.js),
 * inicializa los módulos que se desplazan a lo largo de la vista cuando el
 * usuario hace scroll. Marque los elementos stickables con la clase *stickable*
 *
 * El alto del sticky viene determinado por el alto de un ancestro. Es recomendable usar
 * el alto más alto disponible: ya sea el padre directo, el padre del padre o el tercero. Por defecto
 * se aplicará el espacio disponible en el padre del padre. Si desea establecer otro ancestro, definalo
 * en data-sticky-parent="n" siendo n la distancia al padre.
 *
 * También por defecto se aplica un offset de 90px tal que la altura del elemento sea la propia altura
 * más esos 90px de más (para evitar que el header tape el elemento). Puede personalizar este offset
 * con el atributo data-sticky-offset="m", siendo m el número de píxeles extra.
 *
 */
export default () => {
    try{
        loadStickyElements();
    }catch(e){
        console.error('The module could not be initialized because an error was detected.');
    }
};

function loadStickyElements(){
    //Hacer Wrapper con JQuery para tener la certeza de que ha cargado el DOM y el propio JQuery
    $(function(){
        //Solo en versión Desktop. El breakpoint está en max-1003, luego empezar a mostrarlo en 1004
        if(window.innerWidth < 1004) return;

        $('.stickable').each(function(){

            //Ignore SAS publi
            const id = $(this).attr('id');
            if (id.startsWith("sas_")) {
                return;
            }

            const $elem = $(this);
            //El offset por defecto es 90 (alto probado para evitar el header)
            const offset = (isNaN($(this).data('sticky-offset'))) ? 90 :  parseInt($(this).data('sticky-offset'));

            //Definición de padre a través de atributo data-sticky-parent="n". Por defecto 2.
            const parentLevel = (isNaN($(this).data('sticky-parent')) ? 2 : parseInt($(this).data('sticky-parent')));
            let $parent = $(this);

            //Obtenemos el padre que proporcionará la altura sobre la que se mueve el elemento
            try{
                for(let i = 0; i<parentLevel;i++){
                    $parent = $parent.parent();
                }

                $elem.stick_in_parent({
                    'parent' : $parent,
                    'offset_top': offset,
                    spacer: false
                });
            }catch(e){
                console.error('Unable to find sticky parent. ' + e);
            }
        });



        //Por cada tick de scroll, recalcular las posiciones
        window.onscroll = function() {
            $(document.body).trigger("sticky_kit:recalc");
        };
    });
}