import {sendBeacon} from "../modulesUtils";

/**
 * Noticias (no AMP) de Ei y de Asset Managers
 * <span class="vsr" data-section="$idSection" data-news="$idNews" data-url="$url"/></span>
 * @param {HTMLSpanElement} elem
 */
const sendNewsVisitBeacon = (elem) => {
    const news = elem.dataset.news;
    const section = elem.dataset.section;
    const url = elem.dataset.url;

    if (!url || !section) {
        return;
    }
    const newsIdUrl = !!news ? `id1=${news}&` : '';
    sendBeacon(`${url}?${newsIdUrl}id2=${section}`);
    elem.remove();
}

/**
 * Instrumentos
 * <span class="vsri" data-ticker-id="{{ instrumentDetail.tickerId }}" data-url="{{ path('visits_instrument_ajax') }}"></span>
 * @param {HTMLSpanElement} elem
 */
const sendInstrumentVisitBeacon = (elem) => {
    const id = elem.dataset.tickerId;
    const url = elem.dataset.url;
    if (!id || !url) {
        return;
    }

    sendBeacon(`${url}?id1=${id}`);
    elem.remove();
}

export const sendVisitBeacon = () => {
    document.querySelectorAll('span.vsri').forEach(sendInstrumentVisitBeacon);
    document.querySelectorAll('span.vsr').forEach(sendNewsVisitBeacon);
}