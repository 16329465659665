import {EInterceptionObserver} from "../EIntersectionObserver/EIntersectionObserver";
import "./images.scss";
import {dispatchEvent} from "../../utils/eventDispatch";

export const LAZY_EVENT = 'image:lazy';

const processImage = (image) => {
    if (image.hasAttribute('data-srcset')) {
        const srcsetData = image.getAttribute('data-srcset');
        image.setAttribute('srcset', srcsetData);
    }
    if (image.hasAttribute('data-src')) {
        image.src = image.getAttribute('data-src');
    }
}

export const imagesLazyLoad = () => {
    const selector = 'img.photo:not(.loading):not(.loaded)';
    const elements = document.querySelectorAll(selector);
    const cb = (target) => { processImage(target) };
    const observer = EInterceptionObserver(elements, cb);

    document.addEventListener(LAZY_EVENT, (e) => {
        document.querySelectorAll(selector).forEach(
            item => observer.observe(item)
        );
    });
}

export const dispatchLazyEvent = () => dispatchEvent(LAZY_EVENT);
